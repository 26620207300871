.left-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border-radius: rem(8);
  padding-inline: rem(34);
  background-color: var(--clr-white);
  font-family: var(--font-poppins);

  &__title {
    margin-top: rem(32);
    margin-bottom: rem(28);
  }

  &__img {
    width: 33%;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(28);
    text-align: center;

    &-username {
      font-size: rem(23);
      font-family: var(--font-Semi-bold);
      line-height: rem(31);
      color: var(--clr-black);
    }

    &-desc {
      font-size: rem(18);
      line-height: rem(24);
      color: #8497b5;
      font-family: var(--font-rubik);
      font-weight: var(--font-Regular);
    }
  }

  &__tags {
    font-family: var(--font-rubik);
    font-weight: var(--font-Regular);
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: rem(14);
    margin-top: rem(28);

    > div {
      color: #0001fd;
      font-size: rem(14);
      line-height: rem(19);
      padding: rem(4) rem(8);
      border-radius: 100vh;
      background-color: var(--clr-gray);
    }
  }

  &__btn {
    background-color: var(--clr-primary);
    border: 0;
    color: var(--clr-white);
    width: 100%;
    font-size: rem(20);
    line-height: rem(27);
    padding-block: rem(8);
    font-family: var(--font-poppins);
    font-weight: var(--font-Medium);
    cursor: pointer;
    border-radius: 100vh;
    margin-top: rem(28);
  }

  &__community,
  &__speciality {
    font-size: rem(18);
    line-height: rem(24);
    color: var(--clr-secondary);
    align-self: flex-start;
  }

  &__community {
    font-weight: var(--font-Bold);
    margin-top: rem(28);
  }

  &__speciality {
    font-weight: var(--font-Medium);
    margin-block: rem(8);
  }
}
