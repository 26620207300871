.title {
  width: fit-content;

  &__text {
    font-size: rem(24);
    font-family: var(--font-poppins);
    color: var(--clr-secondary);
    font-weight: var(--font-Semi-bold);
    line-height: rem(32);
  }

  &__underline {
    width: 60%;
    height: rem(3);
    margin-inline: auto;
    background-color: var(--clr-primary);
  }
}
