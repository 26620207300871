.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(5) rem(25);
  border-bottom: rem(0.8) solid rgba(#000, 0.123);

  @include media-up(medium) {
    padding-inline: rem(50);
  }

  .container {
    display: flex;
    align-items: center;
  }

  &__nav {
    margin-left: rem(38);

    @include media-down(medium) {
      display: none;
    }

    &__list {
      display: flex;

      &-item {
        padding-right: rem(38);

        a {
          font-size: rem(18);
          font-weight: var(--font-Semi-bold);

          &.active {
            color: var(--clr-primary);
          }
        }
      }
    }
  }

  &__user {
    display: flex;

    @include media-down(medium) {
      display: none;
    }

    &-img {
      width: rem(38);
      height: rem(38);
      border-radius: 50%;
    }
  }

  &__menu {
    cursor: pointer;
    padding-block: 10px;
    transition: transform 500ms ease-in-out;

    @include media-up(medium) {
      display: none;
    }

    span {
      width: rem(22);
      height: rem(2);
      background-color: var(--clr-primary);
      position: relative;
      display: block;
      transition: all 0.2s ease-in-out;
      border-radius: 100vh;
      user-select: none;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: inherit;
        background-color: var(--clr-primary);
        border-radius: 100vh;
        user-select: none;
      }

      &::before {
        top: -6px;
      }

      &::after {
        top: 6px;
      }
    }

    &.open {
      --px: 4.5px;

      span {
        z-index: 999;
        background-color: transparent;

        &:before {
          transform: rotate(45deg) translate(var(--px), var(--px));
        }
        &:after {
          transform: rotate(-45deg) translate(var(--px), calc(-1 * var(--px)));
        }
      }
    }
  }

  //overlay
  .overlay {
    position: fixed;
    z-index: 998;
    inset: 0;
    color: var(--clr-white);
    transition: transform 500ms cubic-bezier(0.83, 0.16, 0.58, 1);
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 69%,
      rgba(8, 7, 7, 1) 69%,
      rgba(13, 10, 10, 1) 100%
    );
    overflow: hidden;

    .header__nav {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;

      &__list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-item {
          padding: 0;
          &:not(:first-child) {
            margin-top: 1.3rem;
          }
        }
      }
    }
  }
}
