$breakpoints-down: (
  "small": 31.1875em,
  //  499px
  "medium": 56.5em,
  // 904px
  "large": 75em,
  //  1200px
);

$breakpoints-up: (
  "small": 31.25em,
  //  500px
  "medium": 56.5625em,
  //  905px
  "large": 75.0625em,
  //  1201px
);

@mixin media-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}

@mixin media-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}
