*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: rem(12);
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--clr-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--clr-primary);
  border-radius: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}
