.reviews__container-card {
  display: flex;
  margin-bottom: rem(30);

  &-detail {
    display: flex;
    flex-direction: column;
    margin-left: rem(10);

    &__info {
      display: flex;
      justify-content: space-between;

      &-username {
        font-size: rem(18);
        line-height: rem(24);
        color: var(--clr-secondary);
        font-weight: var(--font-Medium);
      }

      &-rate {
        display: flex;
        font-size: rem(20);
        color: var(--clr-secondary);
        line-height: rem(27);
        font-weight: var(--font-Regular);
      }
    }

    &__desc {
      font-size: rem(14);
      font-family: var(--font-rubik);
      font-weight: var(--font-Light);
      line-height: rem(20);
      color: var(--clr-secondary);
    }
  }
}
