.stars {
  position: relative;
  // padding: rem(16);
  white-space: nowrap;
  width: max-content;

  svg {
    //   width: rem(50);
    fill: var(--clr-primary);
  }
}

.cover {
  background: white;
  height: 100%;
  overflow: hidden;
  mix-blend-mode: color;
  position: absolute;
  top: 0;
  right: 0;
}
