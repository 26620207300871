@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Rubik&display=swap");

body {
  min-height: 100vh;
  width: 100%;
  font-family: var(--font-poppins);

  background: var(--clr-gray);
  background: linear-gradient(
    to top right,
    var(--clr-primary) 0%,
    var(--clr-gray) 30%,
    var(--clr-gray) 69%,
    var(--clr-gray) 100%
  );
  background-repeat: no-repeat;
}
