@import "./sass/variables";
@import "./sass/functions";
@import "./sass/reset";
@import "./sass/mixins";
// components
@import "./components/Header/header";
@import "./components/LeftSection/leftsection";
@import "./components/RightSection/rightsection";
@import "./components/Title/title";
@import "./components/Stars/stars";

.main {
  display: flex;
  gap: rem(32);
  margin-top: rem(20);
  max-width: 90%;
  margin-inline: auto;
  margin-block: 2rem;

  @include media-up(medium) {
    max-width: 80%;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
}

.overflow-hidden {
  overflow: hidden;
}
