:root {
  --font-poppins: "Poppins", sans-serif;
  --font-rubik: "Rubik", sans-serif;

  --clr-primary: #ff7b02;
  --clr-secondary: #08092f;
  --clr-blue-100: #0001fd;
  --clr-gray: #f2f6fd;
  --clr-white: #fff;
  --clr-black: #000;

  --font-Light: 300;
  --font-Regular: 400;
  --font-Medium: 500;
  --font-Semi-bold: 600;
  --font-Bold: 700;
}
