.reviews {
  background-color: var(--clr-white);
  border-radius: rem(8);

  &__title {
    margin-inline: auto;
    margin-block: rem(10);
  }

  .container {
    max-width: 80%;
    margin-inline: auto;
  }

  &__total {
    display: flex;
    align-items: center;
    margin-bottom: rem(10);

    > *:not(:first-child) {
      margin-left: 1rem;
    }

    &-number {
      font-size: rem(24);
      line-height: rem(32);
      color: var(--clr-black);
      font-weight: var(--font-Light);
    }

    &-reviews {
      font-size: rem(16);
      line-height: rem(21);
      color: var(--clr-black);
      font-weight: var(--font-Regular);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
  }
}

@import "../Card/card";
