.about {
  background-color: var(--clr-white);
  border-radius: rem(8);

  &__title {
    margin-inline: auto;
    margin-top: rem(10);
  }

  &__desc {
    font-size: rem(18);
    line-height: rem(28);
    color: var(--clr-black);
    font-family: var(--font-rubik);
    font-weight: var(--font-Regular);
    padding: rem(10) rem(20);
  }
}
